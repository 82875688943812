<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card>
            </template>
            <b-alert
                v-if="error.title.length > 0"
                :show="true"
                variant="danger"
            >
                <h4>{{ error.title }}</h4>
                <p>{{ error.message }}</p>
            </b-alert>
            <b-jumbotron
                v-else
            >
                <h4
                    class="mb-4"
                >
                    <b-button
                        variant="link"
                        @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                        class="mr-4"
                    >
                        <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
                    </b-button>
                </h4>
                <f2-form
                    :key="loading"
                    :title="form.form_title"
                    :fields="fields"
                    :prefill="model"
                    :noNumbers="true"
                    @onButtonClick="onClick"
                ></f2-form>
            </b-jumbotron>
        </b-skeleton-wrapper>
    </b-container>
</template>
<script>
import F2Form       from "@/components/form/Form.vue"
import { mapState } from "vuex"
/*import SelectRelationship from '@/components/SelectRelationship.vue'
    components: { SelectRelationship }, */

export default {
    props: [ "formKey" ],
    components: { F2Form },
    data () {
        return {
            loading    : false,
            saving     : false,
            model      : {},
            error      : { title: "", message: "" },
            fields     : [],
            form       : {},
            back_to    : {}
        };
    },
    mounted() {
        this.loadAdminForm();
    },
    methods: {
        hasHistory () { return window.history.length > 2 },
        loadAdminForm() {
            this.loading = true;
            this.$api.get(
                `/api/admin/fetch-form`,
                { params: { key: this.formKey } }
            ).then(({ data }) => {
                if (data.success) {
                    this.form = data.record;
                    if (this.form.form_fields)
                        this.fields  = JSON.parse(this.form.form_fields);
                    if (this.form.back_router_link_to)
                        this.back_to = JSON.parse(this.form.back_router_link_to);
                    if (this.form.prefill_fetch_url)
                        this.loadRecord();
                    else
                        this.loading = false;
                } else {
                    this.$bvToast.toast(data.message, {
                        title           : "Form Fetch Error",
                        variant         : "danger",
                        autoHideDelay   : 5000,
                        appendToast     : true
                    });
                    this.error   = {
                        title   : "Form Fetch Error",
                        message : data.message
                    };
                    this.loading = false;
                }
            });
        },
        loadRecord() {
            this.loading = true;
            if (this.$route.query.id)
                this.$api.get(
                    this.form.prefill_fetch_url,
                    { params: { id: this.$route.query.id } }
                ).then(({ data }) => {
                    this.model   = data.record;
                    this.loading = false;
                });
        },
        saveRecord(model) {
            this.saving       = true;
            this.$api.post(
                this.form.save_record_url,
                model
            ).then(({ data }) => {
                if (data.success) {
                    this.model = data.record;
                    this.$bvToast.toast(data.message, {
                        title           : "Success!",
                        variant         : "success",
                        autoHideDelay   : 5000,
                        appendToast     : true
                    });
                } else {
                    this.$bvToast.toast(data.message, {
                        title           : "Failed!",
                        variant         : "danger",
                        autoHideDelay   : 5000,
                        appendToast     : true
                    });
                }
                if (data.redirect) {
                    this.$router.push(data.redirect);
                }
                this.saving = false;
            });
        },
        onClick(field, model) {
            if (field && field.options) {
                if (field.options.onclick == "submit") {
                    this.saveRecord(model);
                }
            }
        }
    },
    computed: mapState(["semester"])
}
</script>